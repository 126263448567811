<script>
import calendar from "@/components/Calendars.vue";
import mixin from "@/mixins";
import Print from "./components/PrintDataItems";
import MovimentacaoEncomendas from "../../../../components/MovimentacaoEncomendas.vue";
export default {
  components: {
    calendar,
    Print,
    MovimentacaoEncomendas,
  },
  mixins: [mixin],
  data: () => ({
    headers: [
      { text: "Cód. Barra", value: "cod_barras", sortable: false },
      { text: "Data/Hora", value: "data_hora_emissao", sortable: false },
      { text: "Ag. Origem", value: "agencia_criacao", sortable: false },
      { text: "Op. Criação", value: "operador_criacao", sortable: false },
      { text: "Ag. Destino", value: "agencia_entrega", sortable: false },
      { text: "Op. Entrega", value: "operador_entrega", sortable: false },
      { text: "A Pagar?", value: "a_pagar", sortable: false },
      { text: "Status", value: "status_pagamento", sortable: false },
      { text: "Valor", value: "valor_total", sortable: false },
      { text: "Ag. Pagamento", value: "agencia_pagamento", sortable: false },
      { text: "Op. Pagamento", value: "operador_pagamento", sortable: false },
    ],
    search: {
      dateStart: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateEnd: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      agencia: null,
    },
    items: null,
    agencias: [],
  }),
  created() {
    this.mixin;
    this.loaderAgencia();
  },
  methods: {
    async loaderAgencia() {
      this.agencia = [];
      this.$store.dispatch("activeLoader", true);
      const agencias = await this.$http.get("/agencias");
      this.agencias = agencias.data;
      this.$store.dispatch("activeLoader", false);
    },
    async loadValues() {
      this.$store.dispatch("activeLoader", true);
      const res = await this.$http.post(
        "/relatorios/encomendas/detalhado",
        this.search
      );
      if (Array.isArray(res.data)) {
        this.items = null;
      } else {
        this.items = res.data;
      }

      this.$store.dispatch("activeLoader", false);
    },
    getAgenciaNome(id) {
      const agencia = this.agencias.find((e) => e.id == id);
      if (agencia) {
        return agencia.nome;
      }
      return "";
    },
    printItem() {
      this.$htmlToPaper("printReport");
    },
  },
};
</script>
